.app {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.logo {
  width: 150px;
}

.space {
  height: 150px;
}

.store-list {
  display: grid;
  gap: 10px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

@media (min-width: 450px) {
  .store-list {
    grid-template-columns: 50% 50%;
  }
}

.badge {
  display: inline-block;
  height: 60px;
}

.badge.not-available {
  opacity: 0.5;
}
